.main {
  background: #ffffff;
  height: calc(var(--vh, 1vh) * 100);
  @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
    height: 100vw;
  }
  @media screen and (max-width: 1024px) {
    height: auto;
  }
}

.container {
  height: 100%;
  padding: 34px 10% 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
  @media screen and (max-width: 1024px) {
    padding: 0px 30px;
    // overflow: hidden;
    height: auto;
  }
}
.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // background-color: pink;
  // margin-bottom: 3.9vh;
}
.logo {
  width: auto;
  height: 10.2vh;
  margin-bottom: 2.75vh;
  @media screen and (max-width: 1024px) {
    height: 7vh;
    padding-top: 2.37vh;
    margin-bottom: 1vh;
  }
  > img {
    width: auto;
    height: 100%;
  }
}
.title {
  font-style: normal;
  font-family: "AcuminVariableConcept", sans-serif !important;
  font-weight: 600;
  font-size: 42px;
  line-height: 42px;
  text-align: center;
  color: #101820;
  max-width: 824px;
  margin-bottom: 2.5vh;
  @media screen and (max-width: 678px) {
    max-width: 330px;
  }
  &_accent {
    color: #B57345;
  }
  &_accent_secondary {
    @media screen and (min-width: 678px) {
      color: #B57345;
    }
  }

  @media screen and (max-width: 1024px) {
    font-size: 30px;
    line-height: 120%;
  }
  @media screen and (max-width: 678px) {
    font-size: 22px;
    line-height: 120%;
  }
  > span {
    position: relative;
    > img {
      position: absolute;
      width: 100%;
      height: auto;
      top: 100%;
      left: 0;
    }
  }
}

.catalog {
  &__block {
    @media screen and (max-width: 1024px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      // height: calc(100vh - (2.37vh * 3) - 8vh - 72px - 3.5vh);
      // height: calc(84.89vh - 142px - 3.5vh);
      height: calc(84.89vh - 172px - 3.5vh);
    }
    @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
      height: calc(100vw - (2.37vw * 3) - 8vw - 72px - 3.5vw);
    }
  }
  // &_main {
  //   display: flex;
  //   align-items: flex-end;
  //   justify-content: space-around;
  //   height: 35vh;
  //   background-color: palegreen;

  //   .catalog_item {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: flex-end;
  //     align-items: center;
  //     // width: 45%;
  //     // margin: 20px;
  //     // padding: 120px;
  //     img {
  //       width: auto;
  //       height: 100%;
  //       // width: 100%;
  //       // height: auto;
  //     }
  //   }
  // }
  // &_sub {
  //   height: 300px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   margin-top: 60px;
  //   .catalog_item {
  //     width: 20%;
  //     margin: 20px;
  //   }
  // }
  // &_item {
  //   cursor: pointer;
  //   transition: all .4s;
  //   transform: scale(1);

  //   &:hover {
  //       transform: scale(1.08);
  //   }
  // }
}
