.wrap {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 55vw;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 5vh 25px;
  box-sizing: border-box;
  text-align: center;

  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #b57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
  .title {
    position: relative;
    z-index: 9;
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: #b57345;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    @media screen and (max-width: 670px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
}

.loader {
  /* HTML: <div class="loader"></div> */
  width: 120px;
  height: 20px;
  -webkit-mask: linear-gradient(90deg, #b57345 70%, #b57345 0) left/20% 100%;
  background: linear-gradient(#b57345 0 0) left -25% top 0 /20% 100% no-repeat #ddd;
  animation: l7 1s infinite steps(6);
}
@keyframes l7 {
  100% {
    background-position: right -25% top 0;
  }
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
}

.message {
  color: #b57345;
  text-align: center;
  margin: 5px auto;
  font-weight: 600;
}

.errorBox {
  font-size: 1em;
  border-radius: 0.25em;
  padding: 1em 1.25em 1.25em;
  color: #ffffff;
  background-color: #b41414;
}

.errorBox h2 {
  font-size: 1.4em;
  font-weight: normal;
  margin: 0 0 0.6em;
  opacity: 0.95;
  color: #ffffff;
}

.errorBox p {
  font-size: 1em;
  margin: 0 0 0.5em;
  opacity: 0.85;
}

.errorBox:before {
  content: ":(";
  float: right;
  font-size: 2.5em;
  padding: 0.15em 0.3em;
  font-weight: bold;
}
