.wrapper__block {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 55vw;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 60px 25px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #B57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
}

.dots {
  display: flex;
  align-items: center;
  justify-content: center;

  .dot {
    transition: all 0.2s;
    border: 1px solid #46797B;
    background: transparent;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin: 0px 4px;

    &.active {
      background: #46797B;
    }
  }
}

.btn_tutorial {
  transition: all 0.2s;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  user-select: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  color: #101820;

  svg {
    transition: all 0.2s;
    position: relative;
    bottom: 1px;
  }

  &.btn_prev {
    margin-right: 20px;
    svg {
      left: 0;
      margin-right: 7px;
    }

    &:hover {
      svg {
        left: -5px;
      }
    }
  }
  &.btn_next {
    margin-left: 20px;
    svg {
      transform: rotate(180deg);
      right: 0;
      margin-left: 7px;
    }

    &:hover {
      svg {
        right: -5px;
      }
    }
  }

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  padding: 30px 5%;
}
.title {
  font-style: normal;
  font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
  font-size: 30px;
  line-height: 1.3;
  color: #B57345;
  margin-bottom: 30px;
}
.info__wrapper {
  max-width: 350px;
  text-align: left;
}

.step {
  background: #46797B;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  padding: 0;
  margin-right: 10px;
  &_item {
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.3;
    color: #101820;
    margin-bottom: 20px;

    span {
      width: calc(100% - 28px);
      &.gray {
        color: #46797B;
      }
      &.red {
        color: #B57345;
      }
      &.hint {
        font-size: 16px !important;
        color: #46797B;
      }
    }
  }
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 40px;

  .image {
    width: 500px;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.dots {
  &__wrapper {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    margin-top: 35px;
    justify-content: space-between;
  }
}
@media (max-width: 1760px) {
  .image {
    width: 300px !important;
    height: 200px !important;
  }
}
@media (max-width: 1300px) {
  .image {
    width: 250px !important;
  }
  .title {
    font-size: 20px !important;
  }
  .step_item {
    font-size: 12px;
  }
  .wrapper {
    padding: 0;
  }
  .content_wrapper {
    margin-left: 15px;
  }
}
@media (max-width: 1024px) {
  .wrapper__block {
    max-width: 90vw !important;
  }
}
@media (max-width: 750px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .title {
    text-align: center;
  }
  .content_wrapper {
    margin-left: 0 !important;
  }
  .image {
    width: 100% !important;
    margin-top: 20px !important;
  }
  .info__wrapper {
    max-width: 100%;
  }
}
@media (max-width: 470px) {
  .title {
    margin-bottom: 10px;
  }
  .dots {
    margin-bottom: 25px;
    margin-top: 30px;
  }
  .wrapper {
    max-width: 100%;

    &__block {
      padding: 5.5vh 25px 35px 25px;
    }
  }
  .image {
    margin-top: 0 !important;
  }
  .btn_next {
    padding: 10px 0px;
    font-size: 14px;
  }
  .content_wrapper {
    width: 100% !important;
  }
  .dot {
    width: 6px !important;
    height: 6px !important;
    margin: 0px 2px !important;
  }
  .dots {
    margin: 5px 0;
    
    &__wrapper {
      margin-top: 10px;
      bottom: 0;
    }
  }
  .btn {
    &_next {
      margin-left: 10px !important;
      svg {
        width: 14px;
        height: 14px;
      }
      span {
        font-size: 12px;
      }
    }
    &_prev {
      margin-right: 10px !important;
      svg {
        width: 14px;
        height: 14px;
      }

      span {
        font-size: 12px;
      }
    }
  }
}
