.optionsWrap {
    margin-bottom: 25px;
}
.optionsWrapLabel {
    min-height: 20px;
    margin-bottom: 10px;
    color: #46797B;
    text-transform: capitalize;
}
.optionsWrapDesktop {
    display: grid;
    column-gap: 5%;
    row-gap: 5px;
    grid-template-columns: repeat(auto-fit, minmax(110px, 150px));
    justify-content: center;
    @media screen and (max-width:1600px) {
        column-gap: 3%;
    }
    @media screen and (max-width:1400px) {
        column-gap: 2%;
    }
    &_presets {
        grid-template-columns: repeat(auto-fit, minmax(100px, 150px));
        div[class*='option_item'] {
            max-width: none;
            width: max-content;
            height: max-content;
            border: none;
            flex-direction: column;
            text-align: center;
            @media screen and (max-width:1024px) {
                max-width: 47%;
                div[class*='option_title'] {
                    text-align: center;
                }
            }

            div[class*='option_title'] {
                max-width: 150px;
                text-align: center;
            }

            & img {
                width: 130px;
                height: 118px;
            }
        }

        &_sectional {
            grid-template-columns: repeat(auto-fit, minmax(150px, 160px));
            div[class*='option_item'] {
                max-width: none;
                width: auto;
                div[class*='option_title'] {
                    text-align: center;
                }
            }
        }

        &_complete {
            grid-template-columns: repeat(auto-fit, minmax(150px, 300px));
            div[class*='option_item'] {
                max-width: none;
                width: max-content;
                height: max-content;
                border: none;
                flex-direction: column;

                & img {
                    width: auto;
                    height: auto;
                }
            }
        }
    }
}
.optionsWrapMobile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    div[class*='option_item'] {
        margin: 5px;
    }    
}
.showMoreWrap {
    margin-top: 2.5vh;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    color: #46797B;
    text-align: center;
    > span {
        cursor: pointer;
    }
    .open {
        > svg {
            transform: rotate(180deg);
            transition: all .2s ease-in-out;
        }
    }
}
.optionsWrapSlider {
    padding: 0 7vw;
    padding-bottom: 30px;
    @media screen and (max-width:1024px) {
        padding: 0 0 30px 0;
    }
    &_presets {
        div[class*='option_item'] {
            max-width: none;
        }
        div[class*='option_item'] {
            margin: 5px 5px !important;
        }
    }

    div[class*='slick-list'] {
        overflow: visible;
    }
    div[class*='slick-track'] {
        display: flex;
        align-items: stretch;
    }
    div[class*='slick-slide'] {
        height: auto;
        @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
            width: 140px !important;
        }
        > div {
            height: 100%;
        }
    }
    div[class*='option_item'] {
        margin: 0 auto;
    }
    ul[class="slick-dots"] {
        font-size: 0;
        bottom: -20px;
        @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
            width: 100vh;
        }
        li {
            width: auto;
            height: auto;
            &[class*='slick-active'] {
                button {
                    background-color: #B57345;
                }
            }
        }
        button {
            width: 10px;
            height: 10px;
            padding: 0;
            border: 1px solid #B57345;
            border-radius: 50%;
            transition: all .2s ease-in-out;
            &::before {
                content: none;
            }
        }
    }
}