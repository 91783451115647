.accordionWrap {
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  padding-right: 10px;
  margin-left: 20px;
  margin-right: 10px;
  @media screen and (max-width: 1024px) {
    overflow: initial;
  }
}


.acordionItemWrap {
  transition: all 0.2s;
  overflow: hidden;
  max-height: 75px;
  border: 1px solid #101820;
  border-bottom: none;

  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }

  @media screen and (max-width: 1024px) {
    max-height: 10000px !important;
  }
  &:last-child {
    border-bottom: 1px solid #101820;
    border-radius: 0px 0px 8px 8px;

    .acordionItem_head {
      border-bottom: 1px solid rgba(61, 115, 141, 0.5);
    }
    &.active {
      .acordionItem_head {
        border-bottom: none;
      }
      .acordionItem_content {
        border-bottom: 1px solid rgba(61, 115, 141, 0.5);
      }
    }
  }
  &.active {
    height: 100%;
    max-height: 385px;
    transition: 0.3s;
    overflow-y: auto;

    &.accordionPillowsFabrics {
      max-height: calc(100vh - 390px) !important;
    }
    &.accordionFurnitureFabrics {
      max-height: calc(100vh - 390px) !important;
    }

    @media screen and (max-width: 1024px) {
      height: unset;
      max-height: none !important;
      &.accordionPillowsFabrics {
        max-height: none !important;
      }
      &.accordionFurnitureFabrics {
        max-height: none !important;
      }
    }

    .acordionItem_arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    .acordionItem_content {
      opacity: 1;
      max-height: calc(100% - 50px);
      @media screen and (max-width: 1024px) {
        max-height: none !important;
      }
    }
  }
}

@media (max-width: 1024px) {
  .acordionItemWrap {
    &.active {
      max-height: none !important;
    }
  }
}
