.interface {
  &_cart {
    display: grid;
    // grid-template-columns: 150px 1fr;
    grid-template-columns: 1fr 1fr;
    column-gap: 8px;

    // display: flex;
    // justify-content: center;

    padding: 15px 20px;
    background: #e9e7db;
    border-top: 1px solid #101820;
    border-radius: 0px 0px 8px 8px;
    @media screen and (max-width: 1024px) {
      grid-template-columns: 120px 1fr;
      padding: 5px 30px;
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      background: #e9e7db;
      border-radius: 0px;
    }
    @media screen and (max-width: 350px) {
      padding: 8px 15px;
    }
    @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
      bottom: auto;
    }

    .button {
      height: 48px;
      // min-width: 111px;
      max-width: 264px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.2s;
      border-radius: 50px;
      font-size: 14px;
      @media screen and (max-width: 1024px) {
        height: 32px;
        font-size: 12px;
      }
      @media screen and (min-width: 1920px) {
        min-width: 264px;
      }
    }
    &_save {
      @extend .button;
      // flex: 0 0 150px;
      border: 1px solid #b57345;
      font-family: "AcuminVariableConcept", sans-serif !important;
      font-weight: 600;
      line-height: 20px;
      color: #b57345;
      padding: 12px 13px;
      background: transparent;

      @media screen and (min-width: 768px) {
        // min-width: 150px;
        // padding: 14px 26px;
      }
      @media screen and (min-width: 1920px) {
        justify-self: end;
      }

      svg {
        margin-right: 5px;
        path {
          width: 17px;
        }
      }

      &:hover {
        background: #b57345;
        border: 1px solid #fff;
        svg {
          path {
            stroke: #fff;
          }
        }
        span {
          color: #fff;
        }
      }
      &.loading {
        opacity: 0.5;
        cursor: auto;
        background: #fff;
        border: 1px solid #b57345;
        svg {
          path {
            stroke: #b57345;
          }
        }
        span {
          color: #b57345;
        }
      }
    }
    &_print {
      @extend .button;
      // flex: 1 1 25%;
      border: 1px solid transparent;
      padding: 8px 25px;
      background: #b57345;
      color: #ffffff;
      font-family: "AcuminVariableConcept", sans-serif !important;
      font-weight: 600;
      @media screen and (min-width: 768px) {
        padding: 14px 30px;
        font-size: 14px;
      }

      &.hidePrice {
        svg {
          margin-left: 0 !important;
        }
      }

      span:first-child {
        display: flex;
        align-items: center;
        justify-content: center;
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 30px;
          background: #ffffff;
          margin-left: 30px;
        }
      }
      svg {
        width: 20px;
        margin-right: 10px;
        margin-left: 20px;
      }
      &:hover {
        background: transparent;
        border: 1px solid #b57345;
        svg {
          path {
            stroke: #b57345;
          }
        }
        span {
          color: #b57345;
          &:first-child:after {
            background: #b57345;
          }
        }
      }
    }
  }
}

@media (max-width: 470px) {
  .interface_cart {
    justify-content: center;
    display: flex;
  }
  .interface_cart_save {
    padding: 0px 8px;
    max-width: 224px;

    svg {
      position: relative;
      top: -1px;
      width: 18px;
      margin-right: 3px;
    }
  }
  .interface_cart_print {
    max-width: 224px;
    padding: 8px 16px;

    svg {
      position: relative;
      top: -1px;
    }
  }
}

@media (max-width: 335px) {
  .interface_cart_print {
    padding: 8px 14px;
  }
}
