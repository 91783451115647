.btnSend {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px;
    gap: 8px;
    width: 95px;
    position: absolute;
    top: 3px;
    bottom: 3px;
    right: 3px;
    background: #ffffff;
    border: 1px solid #B57345;
    border-radius: 50px;

    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-transform: uppercase;
    color: #B57345;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    &:hover {
        background: #B57345;
        color: #ffffff;
        > svg path {
            stroke: #ffffff;
        }
    }
    > svg path {
        transition: all 0.2s ease-in-out;
    }
    > span {
        display: inline-block;
        padding-top: 3px;
    }

    &.multiple {
        position: relative;
        top: 0;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        margin-top: 10px;
    }
}

.Sent {
    pointer-events: none;
    cursor: default !important;
    opacity: 0.5;
    background-color: #fff !important;

    &:hover {
        background-color: #fff !important;
        opacity: 0.5;

        > svg path {
            stroke: #B57345;
        }
    }
}

@media (max-width: 520px) {
    .btnSend {
        font-size: 12px !important;
    }
}
