.wrapper__block {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 680px;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 3.5vh 55px 30px 55px;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #B57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
}
.icon {
  display: flex;
}
.text {
  padding-top: 3px;
}

.textInfo {
  margin: 20px auto;
  width: 100%;
  max-width: 480px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #101820;
}
.btn {
  transition: all 0.2s;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #B57345;
  border: 1px solid #B57345;
  background: transparent;
  border-radius: 50px;
  padding: 13px 10px;
  width: 200px;
  margin: 0 auto;

  &:hover {
    color: #fff;
    border: 1px solid #fff;
    background: #B57345;
  }
}
.wrap {
  background: #B57345;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 560px;
  justify-content: center;
  font-size: 18px;
  line-height: 1.2;
}

.icon {
  margin-right: 10px;
}

.container {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1024px) {
  .wrapper__block {
    max-width: 90vw !important;
  }
}
@media (max-width: 750px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 520px) {
  .wrapper {
    max-width: 100%;

    &__block {
      padding: 5.5vh 25px 35px 25px;
    }
  }
  .text {
    font-size: 14px;
  }
  .textInfo {
    font-size: 12px;
  }
}
