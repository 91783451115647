.optionsWrap {
    margin-bottom: 25px;
}
.optionsWrapLabel {
    min-height: 20px;
    margin-bottom: 10px;
    color: #46797B;
    text-transform: capitalize;
}
.delete {
    transition: all 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    padding: 10px 5px;
    font-weight: 700;
    background: #de5d5d;
    opacity: 0.8;
    border-radius: 5px;
    color: #fff;
    margin: 20px 0px;
    cursor: pointer;
  
    &:hover {
      opacity: 1;
    }
  }
  
.optionsWrapDesktop {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    // @media screen and (max-width:1600px) {
    //     column-gap: 3%;
    // }
    // @media screen and (max-width:1400px) {
    //     column-gap: 2%;
    // }
    @media screen and (max-width:1024px) {
        display: flex;
        align-items: stretch;
        overflow-x: auto;
        // margin-left: -20px;
        margin-right: -20px;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0;
            height: 0;
        }
    }

    @media screen and (max-width: 450px) {
        justify-content: center;
    }
    > div {
        @media screen and (max-width: 1700px) {
            min-width: 140px;
            height: auto;
            width: 15%;
            margin-right: 5px;
            margin-bottom: 5px;
        }
        @media screen and (max-width:1024px) {
            min-width: 140px;
            height: auto;
            max-width: 11rem;
            // &:first-child {
            //     margin-left: 20px;
            // }
            // &:last-child {
            //     margin-right: 20px;
            // }
        }
    }

    div[class*="img"]{
        min-width: 50px;
    margin-right: 10px;
    height: 50px;
        max-width: 50px;
        max-height: 50px;
        @media screen and (min-width: 1024px) {
            min-width: 50px;
            height: 50px;
        }

        img{
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}