.acordionItem {
  height: 100%;
  // transition: all 0.2s;
  // overflow: hidden;
  // max-height: 75px;
  // border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  // @media screen and (max-width: 992px) {
  //     max-height: none;
  // }
  // &:last-child {
  //     border-bottom: none;
  //     .acordionItem_head {
  //         border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  //     }
  //     &.active {
  //         .acordionItem_head {
  //             border-bottom: none;
  //         }
  //         .acordionItem_content {
  //             border-bottom: 1px solid rgba(61, 115, 141, 0.5);
  //         }
  //     }
  // }

  .acordionItemIndex {
    background: #46797B;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 12px;
    border-radius: 50%;
    margin-right: 6px;
    color: #fff;
    padding-left: 6px;
    padding-right: 5px;

    span {
      position: relative;
      left: -1px;
    }
  }

  &_arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      transition: all 0.2s;
      transform: rotate(180deg);
    }
  }
  &.active {
    // height: 100%;
    // max-height: 100%;
    // transition: 0.3s;
    // @media screen and (max-width: 1024px) {
    //     height: auto;
    //     max-height: none;
    // }
    .acordionItem_arrow {
      svg {
        transform: rotate(0deg);
      }
    }
    .acordionItem_content {
      opacity: 1;
      max-height: calc(100% - 50px);
      @media screen and (max-width: 1024px) {
        max-height: none;
      }
    }
  }
  &_content {
    padding: 0 5.5%;
    transition: opacity 0.3s;
    opacity: 0;
    overflow: auto;
    max-height: 0;
    overflow-x: hidden;
    box-sizing: border-box;
    @media screen and (max-width: 1024px) {
      padding: 0 20px;
    }
    @media screen and (max-width: 350px) {
      padding: 0 15px;
    }
  }
  &_contentInternal {
    // padding: 30px  0 0 0;
    @media screen and (max-width: 1024px) {
      padding: 0;
    }
  }
  &_head {
    padding: 2px 30px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    height: 50px;
    box-sizing: border-box;
    @media screen and (max-width: 1024px) {
      padding: 5px 20px;
      height: 50px;
      align-items: center;
    }
    @media screen and (max-width: 350px) {
      padding: 5px 15px;
    }
    &.disabled {
      opacity: 0.5;
    }
  }
  &_title {
    font-style: normal;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 18px;
    display: flex;
    align-items: center;
    color: #3d738d;
    text-transform: capitalize;
    @media screen and (max-width: 1024px) {
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.option_infoIconWrapper {
  margin-left: 10px;
  margin-bottom: 3px;
}
.filter {
  margin-bottom: 12px;

  &_header {
    font-weight: 400;
    display: flex;
    justify-content: space-between;

    p {
      margin-bottom: 0;
    }

    &_title {
      color: #101820;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 5px;
    }

    &_activeItem {
      color: #b57345;
      font-size: 12px;
      line-height: 12px;
    }

    &_button {
      color: #101820;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      border: 1px solid #9aa0a7;
      border-radius: 8px;
      padding: 15px 8px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;

      svg {
        margin-right: 5px;
        width: 10px;
      }
    }
  }

  &_items {
    display: flex;
  }

  &_search {
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #101820;
    margin-top: 15px;

    input::placeholder {
      color: #101820 !important;
    }
  }
}

@media (max-width: 720px) {
  .filter_header_button {
    font-size: 10px;
    padding: 5px 10px;
    height: 35px;
    img {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }
  }
}
