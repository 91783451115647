.finishGroup {
    margin: 25px 0;
    div[class*="itemClass"] {
        border-radius: 10px;
        overflow: hidden;
        width: max-content;
    }
}
.finishGroupTitle {
    margin: 0 0 10px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #46797B;
      &_accent{
    color: #B57345;
  }
    @media screen and (max-width:1024px) {
        margin: 0 0 15px 0;
    }
}
.finishGroupRow {
    display: grid;
    // grid-template-columns: 1fr 1fr;
    // column-gap: 20px;
    @media screen and (max-width:678px) {
        grid-template-columns: 1fr;
    }
    > div {
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    div[class*='boxItems'] {
        grid-template-columns: repeat(auto-fit, minmax(75px, 50px));
    }
    div[class*='itemClass'] {
        overflow: visible;
    }
    div[class*='itemClass'] > span {
        font-size: 12px;
        overflow-wrap: normal;
    }
}