.option_count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    background-color: #B57345;
    border-radius: 50%;
    color: #ffffff;
    line-height: 1;
    font-weight: 500;
    font-size: 14px;
}