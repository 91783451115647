.modalSaveConfig {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 650px;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 5vh 25px;
  box-sizing: border-box;
  text-align: center;

  .productSKU {
    border-radius: 5px;
    color: #b57345;
    font-weight: 400;
    text-align: center;
    margin: 5px auto;
    &Value {
      font-weight: 600;
    }
  }

  .close {
    position: absolute;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;

    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #b57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
  .title {
    margin: 0 0 10px 0;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: #b57345;
    @media screen and (max-width: 670px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
  .subTitle {
    margin: 0 0 2.5vh 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #3d738d;
  }
  .formWrap {
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
    // margin-bottom: 3.5vh;
  }
  .fildsLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inputWrap:first-child {
      margin-bottom: 20px;
    }
    .inputWrap:last-child {
      flex: 1 1 auto;
    }

    .email {
      display: flex;
      flex-direction: column;
    }
  }
  .inputWrap {
    position: relative;
    width: 80%;
    text-align: left;
    label {
      display: inline-block;
      margin-bottom: 8px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: #3d738d;
    }
    input {
      width: 100%;
      height: 54px;
      padding: 0 15px;
      background: #ffffff;
      border: 1px solid rgba(70, 121, 123, 0.7);
      outline: none;
      border-radius: 50px;

      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #3d738d;
      box-sizing: border-box;
      &:focus,
      &:focus,
      &:hover {
        border: 1px solid rgba(70, 121, 123, 0.7);
      }

      &::-webkit-input-placeholder {
        /* Edge */
        font-weight: 300;
        font-size: 14px;
        color: #3d738d;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        font-weight: 300;
        font-size: 14px;
        color: #3d738d;
      }
      &::placeholder {
        font-weight: 300;
        font-size: 14px;
        color: #3d738d;
      }
    }
    &_fromButton {
      position: relative;
      input {
        padding-right: 105px;
      }
    }

    .fieldCollectionName input {
      border: none;
      border-bottom: 1px solid #9aa0a7;
      border-radius: 0;
    }
  }

  .smallText {
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #3d738d;
    @media screen and (max-width: 670px) {
      line-height: 18px;
    }
  }
  .linkWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .linkText {
      display: block;
      width: 100%;
      max-width: calc(80% - 92px);
      margin: 0 20px 0 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: none;
      @media screen and (max-width: 670px) {
        max-width: calc(90% - 92px);
      }
    }
    .btnCopyLink {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      border: none;
      outline: none;
      background: none;

      font-weight: 700;
      font-size: 16px;
      line-height: 16px;
      letter-spacing: 0.16px;
      color: #b57345;
      cursor: pointer;
      > img {
        margin-right: 5px;
      }
    }
  }
}

.Sent {
  pointer-events: none;
  cursor: default !important;
  opacity: 0.5;
  background-color: #fff !important;

  &:hover {
    background-color: #fff !important;
    opacity: 0.5;

    > svg path {
      stroke: #b57345;
    }
  }
}

@media (max-width: 520px) {
  .btnSend {
    font-size: 12px !important;
  }
  .email_list {
    max-height: 130px;
    overflow-y: auto;
  }
}

.multiple {
  position: relative !important;
  top: 0 !important;
  bottom: 0 !important;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}
