.interface {
    &_tab {
        cursor: pointer;
        transition: all 0.2s;
        font-style: normal;
        font-family: "AcuminVariableConcept", sans-serif !important;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        width: -webkit-fill-available;
        letter-spacing: 0.05em;
        text-transform: capitalize;
        padding: 14px 0;
        background: #fff;
        color: #46797B;
        border: solid 1px #46797B;
        text-align: center;
        &.active {
          background: #46797B;;
          color: #fff;
        }
        &:first-child {
          border-radius: 8px 0px 0px 0px;
        }
        &:last-child {
          border-radius: 0px 8px 0px 0px;
        }
        &__block {
          display: flex;
          align-items: center;
          justify-content: space-around;
          // width: fit-content;
          @media screen and (max-width:1024px) {
            display: none;
          }
        }
      }

    &_oneTab {
      border-radius: 8px 8px 0px 0px !important;
    }
}