.wrapper {
  transition: all 0.2s;
  position: absolute;
  right: 12px;
  bottom: 12%;
  width: 40px;
  height: 40px;
  z-index: 99999;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  border-radius: 10px;
  padding: 10px;
  @media screen and (min-width:678px) {
  top: 50%;
  margin-right: 92px;
  width: 90px;
  height: 90px;
  background: rgba(255, 255, 255, 0.8);
  }

  .delete_img {
    width: 46px;
  }
  &:hover {
    opacity: 1;
  }
  .text {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #3d738d;
    text-align: center;
    margin-top: 10px;
    @media screen and (max-width:678px) {
    display: none;
  }
    .del {
      display: inline-block;
      background: #B57345;
      box-shadow: 0px 1px 0px #85243f;
      border-radius: 5px;
      color: #fff;
      margin: 0px 5px;
      padding: 0px 2px;
      padding-top: 2px;
    }
  }
}
