.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  &:last-child {
    margin-right: 0px;
  }

  .label {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #101820;
  }
}

@media (max-width: 1024px) {
  .container {
    .label {
      font-size: 10px !important;
    }
    .icon {
      transform: scale(0.8) !important;
    }
  }
}

// @media (max-width: 390px) {
//   .icon {
//     transform: scale(0.8);
//   }
//   .label {
//     font-size: 10px !important;
//   }
// }