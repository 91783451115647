.infoPopup {
  width: 50%;
  position: fixed;
  z-index: 99999;
  padding: 10px;
  font-size: 12px;
  color: #101820;
  transform: translate(-91%, -103%);
  border-radius: 10px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  padding-right: 0;
  cursor: default;
     @media screen and (min-width:700px) {
       width: 300px;
      }

      .close {
      }
      .text {
        display: flex;
        align-items: center;
        width: calc(100% - 30px);
      }
  svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
  }

  &:after {
    display: block;
    content: "";
    position: absolute;
    border: 1px solid #9aa0a7;
    right: 8px;
    bottom: -7px;
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #ffffff;
  }
}
