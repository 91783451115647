.pillowsHint {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 10px auto;
  width: 100%;
  max-width: 300px;
  height: 50px;
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 130%;
  text-align: center;
  color: #101820;
}