.mainPreset {
  position: absolute;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  background: #ffffff;
  height: calc(var(--vh, 1vh) * 100);;
  overflow-y: auto;
  @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
    height: 100vw;
  }
}

.container {
  position: relative;
  padding: 3vh 10% 3vh 10%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  @media screen and (max-width:1280px) {
    padding: 3vh 5% 3vh 5%;
  }
  @media screen and (max-width:1024px) {
    padding: 2.37vh 20px 2.37vh 20px;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
  }
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-top: 40px;
  @media screen and (max-width:1024px) {
    padding-top: 20px;
    min-height: auto;
    flex: 1 1 auto;
  }
}

.header {
  position: relative;
  z-index: 11;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  @media screen and (max-width:1024px) {
    position: relative;
    height: 7.25vh;
    width: 100%;
    justify-content: center;
    margin-bottom: 2.37vh;
    flex: 0 0 auto;
  }
}
.logo {
  width: 100%;
  height: 10.2vh;
  cursor: pointer;
  text-align: center;
  @media screen and (max-width:1024px) {
    height: 7.25vh;
  }
  > img {
    width: auto;
    height: 100%;
  }
}
.back {
  width: auto;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  @media screen and (max-width:1024px) {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 18px;
    color: #3d738d;
    padding-left: 14px;
    @media screen and (max-width:1024px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 12px;
      color: #3D738D;
      width: 50px;
    }
  }

  svg {
    transition: all .2s;
    position: relative;
    left: 0;
    @media screen and (max-width:1024px) {
      width: 24px;
      height: auto;
    }
  }
  &:hover {
    svg {
      left: -10px;
    }
  }
}
.info {
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  color: #46797B;
  margin-bottom: 2.75vh;
  @media screen and (max-width:1024px) {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 2.37vh;
  }
}
.preset {
  &__block {
    margin: 0px -50px;
    padding-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
  &_item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0px 50px;
    width: 200px;
  }
}
