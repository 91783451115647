.hint {
  cursor: default;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.3;
  color: #3d738d;
  display: flex;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(90, 102, 112, 0.2);
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 15px;
  svg {
    width: 25px;
    height: 25px;
    position: relative;
    bottom: -7px;
  }
  img {
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    bottom: -7px;
  }
  .text {
    width: calc(100% - 25px);
    margin-left: 10px;
  }

  .short {
    display: none;
  }
  .red {
    color: #B57345 !important;
  }
}

@media (max-width: 1024px) {
  .hint {
    font-size: 10px;
  }
  .short {
    display: inline !important;
    &.active {
      display: none !important;
    }
  }
  .full {
    display: none !important;
    &.active {
      display: inline !important;
    }
  }
}
