.SwitchCenter {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #46797B;

    & .label1 {
        margin-right: 24px;
    }

    & .label2 {
        margin-left: 24px;
    }
}

.switch {
    position: relative;
    margin-bottom: 0;
    margin-bottom: 25px;
    min-width: 190px;

    &::before {
        position: absolute;
        content: "";
        width: 100%;
        height: 100%;
        z-index: -20;
        border-radius: 30px;
        background: rgba(255, 255, 255, 0.7);
        backdrop-filter: blur(14px);
        left: 0;
    }
    & .title {
        margin: 0 0 25px 0;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #46797B;
        @media screen and (max-width:1024px) {
            display: flex;
            align-items: center;
            margin: 0 0 15px 0;
        }
        > span {
            display: inline-block;
            padding: 0 10px;
            position: relative;
            z-index: 100;
        }
        svg {
            display: inline-block;
            cursor: pointer;
            @media screen and (max-width:1024px) {
                width: 20px;
                height: 20px;
            }
        }
    }
    & .label1,
    & .label2 {
        font-size: 18px;
        line-height: 20px;
        font-weight: 600;
        @media screen and (max-width:1024px) {
            font-size: 16px;
        }
    }
}

.switchPorchLoft {
    display: none;
    position: absolute;
    left: 50%;
    bottom: 55px;
    transform: translateX(-50%);
    @media screen and (max-width: 1024px) {
        display: flex;
    }
}
