// Right Controls Block
.interface {
  position: relative;
  // height: calc(var(--vh, 1vh) * 100)
  height: max-content;
  transition: all 0.3s ease-in-out;
  padding-top: 60px;
  @media screen and (max-width: 1024px) {
  //  height: var(--interfaceHeight);
   
    height: calc(var(--vh, 1vh) * 44);
    padding-top: 0;
  }
  @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
    height: 44vw;
  }

  &_header {
    font-style: normal;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 30px;
    line-height: 30px;
    color: #B57345;
    transition: all 0.3s ease-in-out;
    margin-bottom: 10px;
    @media screen and (max-width: 1280px) {
      font-size: 28px;
    }
    @media screen and (max-width: 1100px) {
      font-size: 23px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &_scroll {
    padding-top: 10px;
    background: #E9E7DB;
    overflow-y: auto;
    height: calc(100vh - 277px);
    @media screen and (max-width: 1024px) {
         height: calc(100% - 43px);
      border-radius: 20px 20px 0px 0px;
    }
    @media screen and (max-width: 350px) {
      height: calc(100% - 49px);
    }
  }

  
}
