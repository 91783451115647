.playerWrapper {
    height: 100%;
    width: 100%;
    position: absolute !important;
    top: 0;
    left: 0;
    // z-index: 10;
    @media screen and (max-width:1024px) {
        height: 80%;
        top: auto;
        bottom: 0;
    }
}
.closeFull {
    display: none;
    svg, img {
        width: 30px;
        height: 30px;
    }
}
.player {
    height: 100%;
    width: 100%;
    div[class*="help"] {
        z-index: 12;
    }
    @media screen and (max-width: 650px) {
        user-select: none;
    }
}
.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 0;
    > svg {
        width: 56px;
        height: 56px;
        margin-bottom: 20px;
        // animation:rotate 2s cubic-bezier(0.65, 0.000, 0.75, 1.000) infinite;
        animation: 1s linear 0s normal none infinite running rot;
        -webkit-animation: 1s linear 0s normal none infinite running rot;
    }
    &_text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        color: #46797B;
    }

    &.opacity {
        background: transparent;
    }
}

@keyframes rotate {
    to {
        transform: rotateZ(720deg);
    }
}

@keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@-webkit-keyframes rot {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}


@media (max-width: 1024px) {
    .loader_text {
        font-size: 12px;
    }
}
