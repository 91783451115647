.container {
  transition: all 0.2s;
  position: absolute;
  right: 12px;
  bottom: 12%;
  width: 40px;
  height: 40px;
  z-index: 99999;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0.8;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: transparent;
  padding: 10px;
  background: rgba(222, 222, 225, 1);
  border-radius: 50px;
  top: 50%;
  margin-right: 0px;
  width: 36px;
  height: 80px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  span {
    display: flex;

    svg {
      path {
        transition: all .2s;
      }
    }
  
    &:hover {
      svg {
        path {
         stroke: #B57345;
        }
      }
    }
  }
}


@media (max-width: 1024px) {
  .container {
    display: none;
  }
}