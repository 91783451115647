.catalog {
  &_sub_background {
    height: 20.3vh;
    width: 100%;
    max-width: 1090px;
    background: #E9E7DB;
    border-radius: 40px 40px 0px 0px;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (max-width: 1024px) {
      height: 30%;
    }
  }

  &_sub {
    height: 24vh;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    margin: 7.1vh auto 0;
    max-width: 1090px;

    .catalog_item {
      width: 20%;
      margin: 20px;
      text-align: center;

      >img {
        height: 100%;
        width: auto;
        max-width: 100%;
        object-fit: contain;
      }
    }

    &_mob {
      width: 100%;
      margin-right: auto;
      transition: margin 500ms ease-in-out;
      padding-bottom: 0px;

      .catalog_item {
        width: 120px;
        text-align: center;

        >img {
          width: auto;
          max-width: 100%;
          height: 100%;
          max-height: 10vh;
          margin: 0 auto;
        }
      }

      div[class*="slick-slider"] {
        top: -20px;
        overflow: visible;
      }

      div[class*="slick-list"] {
        overflow: visible;
      }

      ul[class="slick-dots"] {
        position: absolute !important;
        // margin-top: 10px !important;
        font-size: 0;
        bottom: -50px;
        width: calc(100vw - 60px);

        li {
          width: auto;
          height: auto;

          &[class*="slick-active"] {
            button {
              background-color: #B57345;
            }
          }
        }

        button {
          width: 14px;
          height: 14px;
          padding: 0;
          border: 1px solid #B57345;
          border-radius: 50%;
          transition: all 0.2s ease-in-out;

          &::before {
            content: none;
          }
        }
      }
    }
  }

  &_item {
    cursor: pointer;
    transition: all 0.4s;
    transform: scale(1);
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      transform: scale(1.5);
    }

    &:hover {
      transform: scale(1.08);

      @media screen and (max-width: 1024px) {
        transform: scale(1.5);
      }
    }

    &:focus-visible {
      outline: none;
    }

    &_title {
      position: absolute;
      min-height: 60px;
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%);
      font-family: "AcuminVariableConcept", sans-serif !important;
      font-weight: 600;
      font-size: 30px;
      line-height: 30px;
      color: #101820;
      max-width: 222px;

      @media screen and (max-width: 1024px) {
        font-size: 11px;
        line-height: 11px;
        bottom: -7px;

        min-height: unset;
      }

      @media screen and (max-width: 350px) {}
    }
  }
}


@media screen and (max-width: 824px) {
  .mobile_section {
    overflow: hidden;
    padding: 50px 0 140px;
    margin: 10vh 0 -33px 0;
    margin-right: 0px;
  }
}