// .imgRadioWrap {
//     margin-bottom: 10px;
// }

.selectedName {
  position: relative;
  top: -20px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1024px) {
    top: 0;
    margin-bottom: 10px;
  }
  &__label {
    font-size: 18px;
    color: #3d738d;
  }
  &__value {
    font-size: 16px;
    color: #B57345;
  }
}

.boxItemsTitle {
  margin: 0 0 10px 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #46797B;
  @media screen and (max-width: 1024px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
  }
}

.boxItems {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
  column-gap: 14px;
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));
  }

  & .itemClass {
    cursor: pointer;
    // border: 2px solid transparent;
    margin-bottom: 10px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    // max-width: 100px;
    // max-height: 100px;
    background-color: #fff;
    padding-top: 4px;
    padding-bottom: 6px;
    padding-left: 8px;
    padding-right: 8px;
    border-radius: 8px;
    border: solid 1px #9aa0a7;

    &:last-child {
      margin-right: 0;
    }

    &.active {
      border: 2px solid #B57345;
    }
    .imgWrap {
      max-width: 60px;
      max-height: 60px;
      font-size: 0;
      border-radius: 12px;
      // overflow: hidden;
      border: 2px solid transparent;
      transition: all 0.2s ease-in-out;
      box-sizing: border-box;
      > img {
        width: 100%;
        display: block;
      }
    }
    span {
      display: inline-block;
      max-width: 100%;
      margin-top: 10px;
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      text-transform: capitalize;
      color: #3d738d;
      overflow-wrap: break-word;
      // text-transform: capitalize;
      @media screen and (max-width: 1024px) {
        font-size: 14px;
      }
    }
  }
}
