.wrapper__block {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 800px;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 3.5vh 55px 30px 55px;
  box-sizing: border-box;
  text-align: center;
  align-items: center;
  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #B57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
}
.linkWrap {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  max-width: 450px;
  .linkText {
    display: block;
    width: 100%;
    max-width: calc(80% - 92px);
    margin: 0 20px 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    outline: none;
    background: none;
    @media screen and (max-width: 670px) {
      max-width: calc(90% - 92px);
    }
  }
  .btnCopyLink {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
    outline: none;
    background: none;

    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.16px;
    color: #B57345;
    cursor: pointer;
    > img {
      margin-right: 5px;
    }
  }
}
.title {
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 33px;
  text-align: center;
  color: #B57345;
}
.subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #101820;
  padding-bottom: 20px;
  padding-top: 10px;

  a {
    color: #B57345;
  }
}
.btn {
  transition: all 0.2s;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #B57345;
  border: 1px solid #B57345;
  background: transparent;
  border-radius: 50px;
  padding: 13px 10px;
  width: 200px;
  margin: 0 auto;
  margin-top: 40px;

  &:hover {
    color: #fff;
    border: 1px solid #fff;
    background: #B57345;
  }
}
.wrap {
  background: #B57345;
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 10px;
  max-width: 560px;
  justify-content: center;
  font-size: 18px;
  line-height: 1.2;
}

@media (max-width: 1024px) {
  .wrapper__block {
    max-width: 90vw !important;
  }
}
@media (max-width: 750px) {
  .wrapper {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
@media (max-width: 520px) {
  .wrapper {
    max-width: 100%;

    &__block {
      padding: 5.5vh 25px 35px 25px;
    }
  }
  .title {
    font-size: 18px;
    line-height: 23px;
  }
  .subtitle {
    font-size: 16px;
  }
}
