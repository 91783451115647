.email {
    width: 100%;
    .email_list {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        max-height: 290px;
        height: auto;
        overflow: auto;

        .inputWrap {
            margin-bottom: 5px;
        }
        .inputWrap:last-child {
            flex: 1 1 auto;
            margin-bottom: 0;
        }

        .email {
            display: flex;
            flex-direction: column;
        }
    }
}

.inputWrap {
    position: relative;
    width: 100%;
    text-align: left;
    label {
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #3d738d;
    }
    input {
        width: 100%;
        height: 54px;
        padding: 0 15px;
        background: #ffffff;
        border: 1px solid rgba(70, 121, 123, 0.7);
        outline: none;
        border-radius: 50px;

        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #3d738d;
        box-sizing: border-box;
        &:focus,
        &:focus,
        &:hover {
            border: 1px solid rgba(70, 121, 123, 0.7);
        }

        &::-webkit-input-placeholder {
            /* Edge */
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
        &::placeholder {
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
    }
    &_fromButton {
        position: relative;
        input {
            padding-right: 105px;
        }
    }

    .fieldCollectionName input {
        border: none;
        border-bottom: 1px solid #9aa0a7;
        border-radius: 0;
    }
}

.Sent {
    pointer-events: none;
    cursor: default !important;
    opacity: 0.5;
    background-color: #fff !important;

    &:hover {
        background-color: #fff !important;
        opacity: 0.5;

        > svg path {
            stroke: #B57345;
        }
    }
}

.multiple {
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}

.formErrors {
    position: absolute;
    left: 19px;
    top: 6%;

    padding-left: 0px;

    font-size: 14px;
    line-height: 14px;
    color: #B57345;
    position: absolute;
    top: 5px;
    left: 5%;
}

@media screen and (min-width: 992px) and (max-height: 672px) {
    .email {
        .email_list {
            max-height: 200px;
        }
    }
}
@media screen and (min-width: 992px) and (max-height: 570px) {
    .email {
        .email_list {
            max-height: 140px;
        }
    }
}
@media screen and (max-width: 992px) and (max-height: 690px) {
    .email {
        .email_list {
            max-height: 200px;
        }
    }
}
@media screen and (max-width: 992px) and (max-height: 590px) {
    .email {
        .email_list {
            max-height: 140px;
        }
    }
}
