.modalSaveConfig {
  position: absolute;
  width: calc(100% - 30px);
  max-width: 650px;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 5vh 25px;
  box-sizing: border-box;
  text-align: center;
  .close {
    position: absolute;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;

    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #B57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
  .title {
    margin: 0 0 10px 0;
    font-weight: 700;
    font-size: 30px;
    line-height: 33px;
    text-align: center;
    color: #B57345;
    @media screen and (max-width: 670px) {
      font-size: 26px;
      line-height: 28px;
    }
  }
}
.item {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: center;
}
.text {
  font-family: "DM Sans", sans-serif !important;
  margin-left: 30px;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 130%;
  color: #101820;
  max-width: 120px;
  width: 100%;
  text-align: initial;

  .red {
    color: #B57345;
  }
}
