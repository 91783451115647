.player {
    &_interface {
        position: relative;
        // padding: 1.8vh 3.75%;
        padding: 10px 20px 10px 10px;
        background: #E9E7DB;
        border-radius: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        // @media screen and (max-width:1280px) {
        //     display: inline-flex;
        //     flex-direction: column;
        //     justify-content: flex-start;
        //     align-items: flex-start;
        //     padding: 10px;
        //     border-radius: 30px;
        //     margin-right: 40px;
        // }
        @media screen and (max-width: 1024px) {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            border-radius: 0;
            margin-right: 0;
            // background: #fff;
            background: none;
        }
    }
}

// CheckBox Switcher Style
.checkbox {
    transition: all 0.2s;
    cursor: pointer;
    position: relative;
    background: rgba(61, 115, 141, 0.5);
    border-radius: 26px;
    width: 60px;
    height: 30px;
    &::after {
        transition: all 0.2s;
        left: 0;
        position: absolute;
        content: "";
        border: 2px solid rgba(61, 115, 141, 0.5);
        border-radius: 50%;
        background: #fff;
        width: 26px;
        height: 26px;
    }

    &.active {
        &::after {
            left: calc(100% - 30px);
        }
    }
    &_title {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        display: flex;
        align-items: center;
        color: #3d738d;
        padding-left: 12px;
        @media screen and (max-width: 1024px) {
            font-size: 12px;
        }
        > span {
            padding: 0 0.3em;
            @media screen and (max-width: 1024px) {
                display: none;
            }
        }
    }
    &__block {
        display: flex;
        align-items: center;
        margin-right: 15%;
        // @media screen and (max-width:1280px) {
        //     margin-bottom: 10px;
        // }
        @media screen and (max-width: 1024px) {
            margin-bottom: 0;
        }
    }
}
.rotateTutorial {
    margin-right: 10px;
    display: none;
}

.right {
    display: flex;
    align-items: baseline;
}

.camera {
    &__block {
        display: flex;
        align-items: center;
        color: #101820;

        p {
            margin-left: 13px;
            font-weight: 400;
            font-size: 16px;
            line-height: 16px;
            margin-top: 5px;
            margin-bottom: 5px;
        }

        @media screen and (max-width: 1300px) {
            // width: 35%;
             p {
                font-size: 14px;
                line-height: 14px;
             }
        }
    }
    &_rotate {
        width: max-content;
        display: flex;
        align-items: center;
        @media screen and (max-width: 1160px) {
            max-width: 150px;
            transform: translateX(25%);
        }
        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
    // &_item {
    //     transition: all 0.2s;
    //     padding: 8px 10px;
    //     cursor: pointer;
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 16px;
    //     line-height: 16px;
    //     display: flex;
    //     align-items: center;
    //     text-align: center;
    //     color: #3d738d;
    //     border-radius: 50px;
    //     background: transparent;
    //     margin: 0px 8px;
    //     @media screen and (max-width: 1400px) {
    //         padding: 8px 8px;
    //         margin: 0px 2px;
    //     }
    //     &.active {
    //         background: #f9b6aa;
    //     }
    //     @media screen and (max-width: 1024px) {
    //         display: none;
    //     }
    // }
    // &_mob {
    //     display: none;
    //     @media screen and (max-width: 1024px) {
    //         display: block;
    //     }
    //     // position: absolute;
    //     // right: 0;
    //     // bottom: calc(100% + 10px);
    //     // border: 1px solid #3d738d;
    //     // border-radius: 23px;
    //     // padding: 7px 11px;
    //     // font-size: 0;
    //     // background: #ffffff;
    // }
}

.full_screen {
    transition: all 0.2s;
    cursor: pointer;
    margin-left: 15px;
    // @media screen and (max-width:1280px) {
    //     position: absolute;
    //     right: 0;
    //     bottom: 0;
    //     margin-left: 0;
    // }
    @media screen and (max-width: 1024px) {
        display: none;
        position: absolute;
        right: 0;
        bottom: 48vh;
        margin-left: 0;
    }
    @media screen and (max-width: 678px) {
        bottom: calc(100% + 28vh);
    }
    &:hover {
        transform: scale(1.08);
    }
    > svg {
        @media screen and (max-width: 1024px) {
            width: 20px;
            height: 20px;
        }
    }
}

.right {
    img {
        width: 26px;
        height: 30px;
        padding-bottom: 4px;
    }
}

.ar {
    display: none;
    transition: all 0.2s;
    padding: 3px 8px;
    border: 1px solid #3d738d;
    border-radius: 99px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @media screen and (max-width: 1024px) {
        display: flex;
        background: #ffffff;
    }

    span {
        transition: all 0.2s;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 0.02em;
        text-transform: initial;
        color: #3d738d;
        background: transparent;
        padding-left: 10px;
    }

    svg {
        width: 19px;
        height: 22px;
        transition: all 0.2s;
        path {
            transition: all 0.2s;
        }
    }
    &:hover {
        border: 1px solid #3d738d;
        background: #3d738d;

        span {
            color: #fff;
        }
        svg {
            path {
                stroke: #fff;
            }
        }
    }
}

.bedDriverControl {
    position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 2px;
    gap: 5px;
    background: #E9E7DB;
    border-radius: 50px;
    button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 8px 10px 6px;
        background: transparent;
        border-radius: 50px;
        border: 1px solid #9AA0A7;

        font-family: 'DM Sans' !important;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: #9AA0A7;

        box-sizing: border-box;
        transition: all .2s ease-in-out;
        cursor: pointer;
        &:hover {
            background: #B57345;
            border-color: #B57345;
            color: #FFFFFF;
        }
        &.active {
            background: #B57345;
            border-color: #B57345;
            color: #FFFFFF;
            cursor: auto;
        }
    }
}

@media (max-width: 1024px) {
    .FullWindow {
        display: none;
    }
    .rotateTutorial {
        display: flex;
    }
}