.wrapper__block {
  position: absolute;
  width: calc(50% - 30px);
  min-width: 320px;
  max-width: 1400px;
  height: auto;
  max-height: calc(100% - 30px);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 4px 21px rgba(0, 0, 0, 0.25);
  border-radius: 15px;
  padding: 5.5vh 25px 60px 25px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  .close {
    position: absolute;
    z-index: 10;
    width: 19px;
    height: 19px;
    right: 22px;
    top: 22px;
    cursor: pointer;
    @media screen and (max-width: 670px) {
      right: 15px;
      top: 15px;
    }
    &:hover {
      > svg path {
        stroke: #b57345;
      }
    }
    > svg path {
      transition: all 0.2s ease-in-out;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
  max-width: 666px;

  svg {
    path, circle {
      stroke: #a3683d;
    }
  }
}
.img {
  width: 100%;
}
.title {
  color: #b57345;
  font-family: "AcuminVariableConcept", sans-serif !important;
  text-align: center;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 43.2px */
  margin-top: 30px;
  margin-bottom: 16px;
  max-width: 510px;
}
.subtitle {
  color: #b57345;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 26px */
  margin-bottom: 16px;
  max-width: 510px;
}
.btn {
  border-radius: 50px;
  background: #b57345;
  cursor: pointer;
  transition: all 0.2s;
  text-align: center;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
  align-items: center;
  padding: 14px 24px;
  &:hover {
    background: #a3683d;
  }
}

@media (max-width: 1024px) {
  .title {
    font-size: 24px;
  }
  .subtitle {
    font-size: 16px;
  }
  .wrapper__block {
    max-width: 90vw !important;
  }
}

@media (max-width: 470px) {
  .wrapper {
    max-width: 100%;

    &__block {
      padding: 5.5vh 25px 35px 25px;
    }
  }
}
