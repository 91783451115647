

.loader {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    svg {
      width: 33px;
      height: 32px;
      margin-bottom: 30px;
      animation: 1s linear 0s normal none infinite running rot;
      -webkit-animation: 1s linear 0s normal none infinite running rot;
    }
  
    .loader_text {
      font-weight: 400;
      font-size: 20px;
      line-height: 26px;
      color: #101820;
    }
  }
  


  @keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes rot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  







@media (max-width: 1024px) {
    .loader_text {
      font-size: 12px !important;
      line-height: 16px !important;
    }
  }
  