.address {
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  @media screen and (max-width:1024px) {
    font-size: 10px;
    line-height: 12px;
  }
  
  & span:first-child {
    color: #101820;
    cursor: pointer;
  }
  & span:nth-child(2) {
    color: #101820;
    cursor: pointer;
  }
  & span:last-child {
    text-transform: capitalize;
    color: #9AA0A7;
    padding-left: 4px;
  }
}