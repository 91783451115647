.optionsWrap {
  margin-bottom: 25px;
}
.optionsWrapLabel {
  min-height: 20px;
  margin-bottom: 8px;
  color: #46797B;
  text-transform: capitalize;
  &_accent {
    color: #B57345;
  }
}
.optionsWrapNote {
  margin-bottom: 10px;
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #B57345;
}

.optionsWrapDesktop {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(160px, auto));
  justify-items: center;
  align-self: center;

  div[class*="option_item"] {
    justify-items: center;
    max-width: 200px;
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #ffffff;
    // border: 1px solid #9AA0A7;
    border-radius: 8px;
    padding: 8px;

    div[class*="title"] {
      // text-align: left;
      // font-size: 12px;
      // line-height: 12px;
      // margin: 9px 0 9px 5px;
      font-family: 'DM Sans', sans-serif  !important;
      font-weight: 400 !important;
    }

    div[class*="img"] {
      margin-right: 10px;
      min-width: 50px;
      height: 50px;
      max-width: 50px;
      max-height: 50px;
      @media screen and (min-width: 1024px) {
        min-width: 50px;
        height: 50px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  @media screen and (max-width: 1024px) {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    grid-gap: 4px;
    justify-items: center;
    div[class*="option_item"] {
      width: 100%;
    }
  }
}
