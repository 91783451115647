.formWrap {
    width: 100%;
    max-width: 490px;
    margin: 0 auto;
    // margin-bottom: 3.5vh;
}
.fildsLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    .inputWrap:first-child {
        margin-bottom: 20px;
    }
    .inputWrap:last-child {
        flex: 1 1 auto;
    }

    .email {
        display: flex;
        flex-direction: column;
    }
}
.inputWrap {
    margin-top: 20px;
    position: relative;
    width: 80%;
    text-align: left;
    label {
        display: inline-block;
        margin-bottom: 8px;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #3d738d;
        display: flex;
        & .error {
            margin-left: 7px;
        }
    }
    input {
        width: 100%;
        height: 54px;
        padding: 0 15px;
        background: #ffffff;
        border: 1px solid rgba(70, 121, 123, 0.7);
        outline: none;
        border-radius: 50px;

        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        color: #3d738d;
        box-sizing: border-box;
        &:focus,
        &:focus,
        &:hover {
            border: 1px solid rgba(70, 121, 123, 0.7);
        }

        &::-webkit-input-placeholder {
            /* Edge */
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
        &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
        &::placeholder {
            font-weight: 300;
            font-size: 14px;
            color: #3d738d;
        }
    }
    &_fromButton {
        position: relative;
        input {
            padding-right: 105px;
        }
    }

    .fieldCollectionName input {
        border: none;
        border-bottom: 1px solid #9aa0a7;
        border-radius: 0;
    }
}

.smallText {
    margin-bottom: 10px;
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    color: #3d738d;
    @media screen and (max-width: 670px) {
        line-height: 18px;
    }
}
.linkWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    .linkText {
        display: block;
        width: 100%;
        max-width: calc(80% - 92px);
        margin: 0 20px 0 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border: none;
        outline: none;
        background: none;
        @media screen and (max-width: 670px) {
            max-width: calc(90% - 92px);
        }
    }
    .btnCopyLink {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0;
        border: none;
        outline: none;
        background: none;

        font-weight: 700;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.16px;
        color: #B57345;
        cursor: pointer;
        > img {
            margin-right: 5px;
        }
    }
}

.Sent {
    pointer-events: none;
    cursor: default !important;
    opacity: 0.5;
    background-color: #fff !important;

    &:hover {
        background-color: #fff !important;
        opacity: 0.5;

        > svg path {
            stroke: #B57345;
        }
    }
}

@media (max-width: 520px) {
    .btnSend {
        font-size: 12px !important;
    }
    .email_list {
        max-height: 130px;
        overflow-y: auto;
    }
}

.multiple {
    position: relative !important;
    top: 0 !important;
    bottom: 0 !important;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 10px;
}
