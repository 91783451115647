.option {
  &_item {
    position: relative;
    transition: all 0.2s;
    display: flex;
    // flex-direction: column;
    // justify-content: space-between;
    align-items: center;
    // margin: 10px 18px;
    padding: 10px;
    // padding-left: 10px;
    // padding-right: 10px;
    // width: 30%;
    // width: 196px;
    // height: 76px;
    // min-width: 150px;
    // width: calc(20% - 36px);
    cursor: pointer;
    border-radius: 8px;
    position: relative;
    box-sizing: border-box;
    border: 2px solid #9aa0a7;
    transition: all 0.2s ease-in-out;
    background: #fff;
    width: 206px;
    height: 76px;
    // margin-right: 10px;
    // margin-bottom: 10px;

    &:hover {
      border: 2px solid #b57345;

      .option_btn {
        background: #34637a;
        opacity: 1;
      }
    }
    &.disable {
      pointer-events: none !important;
      opacity: 0.4 !important;

      .option_btn {
        position: relative !important;
        background: rgba(61, 115, 141, 0.5) !important;
      }
    }
    &.inActive {
      pointer-events: none !important;
    }

    @media screen and (max-width: 1024px) {
      margin: 5px 5px;
      // max-width: 140px;
      box-sizing: border-box;
      height: 100%;
      padding: 10px 5px;
    }
    &_active {
      border: 2px solid #b57345;
      .option_btn {
        opacity: 1;
      }
      &.disable {
        pointer-events: none;
        opacity: 1;
      }
    }
    &_info {
      padding-right: 24px !important;
    }
  }
  &_count {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 6px;
    left: 6px;
    width: 20px;
    height: 20px;
    background-color: #b57345;
    border-radius: 50%;
    color: #ffffff;
    line-height: 1;
    font-weight: 500;
    font-size: 14px;
  }
  &_img {
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      cursor: pointer;
    }

    &.small_img {
      min-width: 45px !important;
      max-width: 45px !important;
    }
  }
  &_title {
    font-style: normal;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 16px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #46797b;
    margin: 10px 0;
    text-align: left;
    transition: all 0.3s ease-in-out;
    text-transform: capitalize;

    &.limited_width {
      max-width: 60px;
      font-size: 14px;
    }

    &.small_title {
      font-size: 14px;

      width: calc(100% - 90px) !important;
    }
    @media screen and (max-width: 1400px) {
      // font-size: 16px;
      // line-height: 16px;
    }
    @media screen and (max-width: 1024px) {
      margin: 8px 0;
      font-size: 12px;
      line-height: 12px;
    }
  }
  &_infoIconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 3px;
    margin: auto;
    width: 16px;
    height: 16px;
    @media screen and (max-width: 768px) {
      padding: 3px 3px 0 0;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  &_btn {
    transition: all 0.2s;
    cursor: pointer;
    background: #3d738d;
    border-radius: 500px;
    padding: 8px 10px;
    padding-top: 12px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #ffffff;
    text-align: center;
    white-space: nowrap;
    opacity: 0.5;
    @media screen and (max-width: 1024px) {
      font-size: 14px;
      line-height: 14px;
    }

    > span[class="plus"] {
      position: relative;
      display: inline-block;
      padding-left: 1em;
      background-color: pink;
    }
    > span[class="plus"]::before {
      position: absolute;
      width: 1px;
      height: 1em;
      top: 50%;
      left: 0.5em;
      background-color: #fff;
    }
  }
  &_addButton {
    display: inline-flex;
    align-items: center;
    > svg {
      margin-top: -0.25em;
      width: 1.5em;
      padding-right: 0.5em;
    }
  }
  &_info {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    font-size: 0;
    @media screen and (max-width: 1400px) {
      right: 5 px;
    }
  }
  &_countControlWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    right: 7px;
    transform: translateY(-50%);
    width: 26px;
    button {
      width: 100%;
      padding: 2px;
      margin: 0;
      border: none;
      background: none;
      font-size: 0;
      cursor: pointer;
      pointer-events: all;

      img {
        width: 100%;
      }
      &:hover {
        > img {
          path {
            stroke: #b57345;
          }
        }
      }
      &.disabled {
        cursor: auto;
        pointer-events: none;
        > img {
          path {
            stroke: #e9e7db;
          }
        }
      }
      > img {
        path {
          transition: stroke 0.2s ease-in-out;
          stroke: #9aa0a7;
        }
      }
    }
  }
}

.description {
  position: relative;
  top: -5px;
  display: flex;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #46797b;
  max-width: 120px;
}

.Clarification {
  display: flex;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  justify-content: center;
  font-family: "DM Sans", sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #101820;
  max-width: 100px;
}
