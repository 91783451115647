.errorModel {
    position: absolute;
    z-index: 13;
    background: #fff;
    padding: 10px;
    color: #B57345;
    display: flex;
    flex-direction: column;
    font-size: 12px;
    line-height: 1.3;
    box-shadow: 0px 4px 21px rgb(0 0 0 / 25%);
    border-radius: 15px;
    left: 73px;
    top: 8px;
}
.errorIcon {
    width: 14px;
    position: absolute;
    left: 47px;
    top: 0px;
    z-index: 35;
    * circle {
        fill: #B57345;
    }
}
.itemError {
    display: flex;
    span {
        width: 43px;
    }
}
