.wrap {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 992px) and (orientation: landscape) {

    .wrap {
        align-items: center;
        background-color: #e5e5e5;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        height: 100vh;
        left: 0;
        padding: 30vh 10vh;
        position: absolute;
        top: 0;
        -webkit-transform: translate(0%, -100%) rotate(90deg);
        transform: translate(0%, -100%) rotate(90deg);
        width: 100vw;
        z-index: 999999999;
        transform-origin: bottom left;
    }
}


.text {

    color: #B57345;
    font-size: 22px;
    text-align: center;
    color: #3d738d;
}