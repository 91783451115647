.catalog {
    &_main {
        display: flex;
        align-items: flex-end;
        justify-content: space-evenly;
        height: 35vh;
        // background-color: palegreen;

        .catalog_item {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            height: 100%;
            img {
                width: auto;
                height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
            &:nth-child(2){
                img{
                        margin-top: -14px;
                    }
                }
        }
        &_mob {
            margin-left: -30px;
            margin-right: -30px;
            .catalog_item {
                width: 100%;
                height: 28vh;
                display: flex;
                justify-content: center;
                text-align: center;
                > img {
                    width: auto;
                    max-width: 100%;
                    height: auto;
                    height: 100%;
                    max-height: 28vh;
                    object-fit: contain;
                    margin: 0 auto;
                }
            }
            ul[class="slick-dots"] {
                font-size: 0;
                bottom: -20px;
                li {
                    width: auto;
                    height: auto;
                    &[class*='slick-active'] {
                        button {
                            background-color: #B57345;
                        }
                    }
                }
                button {
                    width: 14px;
                    height: 14px;
                    padding: 0;
                    border: 1px solid #B57345;
                    border-radius: 50%;
                    transition: all .2s ease-in-out;
                    &::before {
                        content: none;
                    }
                }
            }
        }
    }
    &_item {
        cursor: pointer;
        transition: all 0.4s;
        transform: scale(1);
        &:hover {
            transform: scale(1.08);
            @media screen and (max-width:1024px) {
                transform: scale(1);
            }
        }
        &:focus-visible {
            outline: none;
        }
        &_title {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            font-family: "AcuminVariableConcept", sans-serif !important;
            font-weight: 600;
            font-size: 30px;
            line-height: 30px;
            color: #101820;
            text-align: center;
            @media screen and (max-width:1024px) {
                font-size: 24px;
                line-height: 24px;
            }
            @media screen and (max-width:350px) {
                font-size: 20px;
                line-height: 20px;
            }
        }
    }
}
