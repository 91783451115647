.addAnotherEmail {
    display: flex;
    color: #101820;
    font-weight: 500;
    font-size: 12px;
    line-height: 130%;
    margin-top: 10px;
    align-items: flex-start;
    margin-left: 1rem;
    cursor: pointer;

    img {
        margin-right: 7px;
    }
}
