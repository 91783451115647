// Content Grid
.mainConfigurator {
  position: relative;
  z-index: 100;
}
.container {
  position: relative;
  padding: 0 5vw 0 0;
  height: calc(var(--vh, 1vh) * 100);;
  transition: all .3s ease-in-out;
  @media screen and (max-width: 1400px) {
    padding: 0 30px 0 0;
  }
  @media screen and (max-width:1024px) {
    padding: 0;
  }
}

// .header {
//   display: flex;
//   justify-content: flex-start;
//   height: 8.5vh;
//   padding-right: 16%;
//   @media screen and (max-width:1024px) {
//     justify-content: center;
//     padding-right: 0;
//     height: 5vh;
//   }
//   > .logo svg {
//     height: 83%;
//     cursor: pointer;
//     @media screen and (max-width:1024px) {
//       height: 100%;
//     }
//   }
// }

.wrapper {
  display: grid;
  grid-template-columns: 1fr 33%;
  column-gap: 0;
  transition: all .3s ease-in-out;
  height: 100%;
  @media screen and (max-width: 1280px) {
    grid-template-columns: 1fr 420px;
  }
  @media screen and (max-width:1024px) {
    display: block;
  }
}






.rotate_mob {
  display: none;
  @media screen and (max-width: 1024px) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: calc(100% + 2vh);
      margin-left: 0;
      display: flex;
      align-items: center;
  }
  > img {
      @media screen and (max-width: 1024px) {
          width: 24px;
          height: 24px;
      }
  }

  p {
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    color: #101820;
    margin-left: 5px;
  }
}



// Player Block
.player {
  position: relative;
  height: 100%;
  @media screen and (max-width:1024px) {
 //   height: var(--playerHeight);

    height: calc(var(--vh, 1vh) * 56);
  }
  @media screen and (max-width:350px) {
    padding: 0 15px;
  }
  @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
    height: 56vw;
  }
  &_header {
    position: absolute;
    z-index: 11;
    top: 10px;
    left: 5vw;
    right: 4%;
    box-sizing: border-box;
    @media screen and (max-width:1024px) {
      top: 10px;
      left: 20px;
      right: 20px;
    }
    .ar {
      @media screen and (max-width:1024px) {
        display: none;
      }
    }
    div[class*="interface_tab"] {
      font-size: 14px;
      line-height: 20px;
      padding: 1.5vh 5vw 1.1vh 5vw;
      @media screen and (max-width:350px) {
        font-size: 13px;
      }
      @media screen and (min-width: 320px) and (max-width: 1024px) and (orientation: landscape) {
        padding: 1.5vh 5vh 1.1vh 5vh;
      }
      @media (max-width: 520px) {
        padding: 1px 20px;
      }
      &:first-child {
        margin-left: 0;
      }
      &:last-child {
        margin-right: 0;
      }
    }
    div[class*="interface_tab__block"] {
      display: none;
      @media screen and (max-width:1024px) {
        display: flex;
        padding: 0;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
    .full_screen_mob {
      display: none;
      @media screen and (max-width: 1024px) {
          position: absolute;
          right: 0;
          top: calc(100% + 2vh);
          margin-left: 0;
          display: block;
      }
      > svg {
          @media screen and (max-width: 1024px) {
              width: 20px;
              height: 20px;
          }
      }
    }
  }
  &_footer {
    position: absolute;
    z-index: 11;
    left: 5vw;
    right: 4%;
    bottom: 5vh;
    @media screen and (max-width:1024px) {
      width: auto;
      left: 20px;
      right: 20px;
      bottom: 1.75vh;
    }
    @media screen and (max-width:350px) {
      left: 15px;
      right: 15px;
    }
  }
  &_interface {
    padding: 1.8vh 3.75%;
    background: #fffaf5;
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width:1024px) {
      padding: 0;
      background: #fff;
    }
  }
}

.header {
  display: flex;
  justify-content: flex-start;
  height: 8vh;
  padding-right: 16%;
  @media screen and (max-width:1024px) {
    justify-content: center;
    padding-right: 0;
    height: unset;
  }
  > .logo img {
    height: 64%;
    width: auto;
    cursor: pointer;
    @media screen and (max-width:1024px) {
      width: 80px;
      height: 100%;
    }
  }
}

.back {
  display: inline-flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  color: #3d738d;
  cursor: pointer;
  @media screen and (max-width:1024px) {
    font-size: 14px;
  }
  span {
    padding-left: 0;
    padding-bottom: 0px;
  }
}

.info {
  font-style: normal;
  font-family: "AcuminVariableConcept", sans-serif !important;
  font-weight: 600;
  font-size: 36px;
  line-height: 42px;
  color: #B57345;
  transition: all .3s ease-in-out;
  display: none;

  @media screen and (max-width: 1280px) {
    font-size: 28px;
  }
  @media screen and (max-width: 1100px) {
    font-size: 23px;
  }
  @media screen and (max-width:1024px) {
    font-size: 12px;
    line-height: 12px;
    display: flex;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
    @media screen and (max-width:1024px) {
      margin-top: 0;
    }
  }
}

.ar {
  transition: all 0.2s;
  padding: 8px 16px;
  border: 1px solid #3d738d;
  border-radius: 99px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  span {
    transition: all 0.2s;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #3d738d;
    background: transparent;
    padding-left: 10px;
  }

  svg {
    transition: all 0.2s;
    path {
      transition: all 0.2s;
    }
  }
  &:hover {
    border: 1px solid #3d738d;
    background: #3d738d;

    span {
      color: #fff;
    }
    svg {
      path {
        stroke: #fff;
      }
    }
  }
}

.scene {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);;
  @media screen and (max-width:1024px) {
    height: 35vh;
  }
  @media screen and (max-width:678px) {
    height: 35vh;
  }
  img {
    max-width: 100%;
  }
}




