.preElection {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    margin-bottom: 5.5vh;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    // overflow: hidden;
    @media screen and (max-width:1024px) {
        margin-bottom: 2.37vh;
    }
    &:last-child {
        margin-bottom: 0;
        div[class*='optionsWrap'] {
            margin-bottom: 0;
        }
    }
    div[class*='optionsWrap'] {
        margin-bottom: 0;
    }
}

.title {
    font-style: normal;
    font-family: "AcuminVariableConcept", sans-serif !important;
    font-weight: 600;
    font-size: 32px;
    line-height: 130%;
    text-align: center;
    color: #B57345;
    margin-bottom: 1vh;
    @media screen and (max-width:1024px) {
        font-size: 26px;
        line-height: 100%;
        margin-bottom: 5px;
    }
}

.subtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #46797B;
    margin-bottom: 4vh;
    @media screen and (max-width:1024px) {
        font-size: 14px;
        margin-bottom: 2.5vh;
    }
}

.scratch__block {
    margin: 60px -18px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
